<template>
  <div class='fill-height' v-if='LOGGED_IN' id='incoming'>

    <FileDropper ref='FileDropper' @uploaded='loadInbox' :endpoint="`file/upload/account/${ACCOUNT.xid}`" />
    <JobPicker ref='JobPicker'/>
    <div id='scrollArea' class='fill-height' @dragenter='dragEnter'>
      <v-container><v-row justify='center'><v-col cols=12 xl=10>
        <v-row>
          <v-col v-for='file in files' v-bind:key='file.xid' cols=12 md=6 lg=4>
            <v-card class='pa-1'>
              <div class='d-flex'>
                <div  class='flex-shrink-0 align-self-center py-1' style='width: 80px; height: 90px;'>
                  <FileIcon :file='file' />
                </div>
                <div class='text-truncate flex-grow-1'>
                  <v-card-text class='py-1'>
                    <!-- SCRIPT FILE -->
                    <div v-if='file.script' class='text-caption'>
                      <div class='d-flex'>
                        <span class="font-weight-medium text-truncate">{{types[file.script.type]}}</span>
                        <v-spacer />
                        <span class="secondary--text"><Moment :timestamp="file.created_at" mode="calendar" tooltip tooltip-type="datetime" /></span>
                      </div>
                      <div class='secondary--text text-truncate'>{{file.script.lasercam_job_number}}</div>
                      <div class='secondary--text text-truncate'>{{file.script.clinicianName}}</div>
                      <div class='secondary--text text-truncate'>{{file.script.clinicName}}</div>
                    </div>
                    <!-- NOT SCRIPT FILE -->
                    <div v-else class='text-caption'>
                      <div class='d-flex'>
                        <span class="font-weight-medium text-truncate">{{file.filename}}</span>
                        <v-spacer />
                        <span class="secondary--text"><Moment :timestamp="file.created_at" mode="calendar" tooltip tooltip-type="datetime" /></span>
                      </div>
                      <div class='secondary--text text-truncate'>{{string_bytes(file.size_bytes)}}</div>
                      <div class='secondary--text text-truncate'>{{filetype(file)}}</div>
                      <div class='secondary--text text-truncate'></div>
                    </div>

                  </v-card-text>
                </div>
              </div>
              <v-card-actions class=''>
                <template v-if='file.script'>
                  <v-btn v-if='file.script.hasPDF' x-small fab outlinedx elevation=2 color='primary' @click='openScriptPdf(file)'><v-icon>mdi-file-document</v-icon></v-btn>
                  <v-btn v-if='file.script.hasScans' x-small fab outlinedx elevation=2 color='primary' @click='openScansInPrescribe(file)'><v-icon>mdi-rotate-3d</v-icon></v-btn>
                  <v-menu>
                    <template v-slot:activator='{on,attrs}'>
                      <v-btn v-on='on' v-bind='attrs' x-small fab outlined xelevation=2 color='primary' class='mx-2'><v-icon>mdi-dots-horizontal</v-icon></v-btn>
                    </template>
                    <v-list>
                      <v-list-item link small @click='downloadFile(file)'><v-icon class='mr-4 primary--text'>mdi-download</v-icon>Download</v-list-item>
                      <v-list-item link small @click='deleteFile(file)'><v-icon class='mr-4 error--text'>mdi-trash-can</v-icon>Delete</v-list-item>
                      <v-list-item link small @click='matchingJobs(file)'><v-icon class='mr-4 primary--text'>mdi-magnify</v-icon>Matching Jobs</v-list-item>
                    </v-list>
                  </v-menu>
                  <v-spacer />
                  <v-btn small outlinedx color='primary' class='pa-4' @click='moveToJob(file)'><v-icon class='mr-2' small>mdi-briefcase-variant</v-icon>Add to Job</v-btn>
                </template>
                <template v-else>
                  <v-btn x-small fab outlinedx elevation=2 color='primary' @click='downloadFile(file)'><v-icon>mdi-download</v-icon></v-btn>
                  <v-menu>
                    <template v-slot:activator='{on,attrs}'>
                      <v-btn v-on='on' v-bind='attrs' x-small fab outlined xelevation=2 color='primary' class='mx-2'><v-icon>mdi-dots-horizontal</v-icon></v-btn>
                    </template>
                    <v-list>
                      <v-list-item link small @click='deleteFile(file)'><v-icon class='mr-4 error--text'>mdi-trash-can</v-icon>Delete</v-list-item>
                    </v-list>
                  </v-menu>
                  <v-spacer />
                  <v-btn small outlinedx color='primary' class='pa-4' @click='moveToJob(file)'><v-icon class='mr-2' small>mdi-briefcase-variant</v-icon>Add to Job</v-btn>
                </template>
              </v-card-actions>
            </v-card>
          </v-col>

        </v-row>
          </v-col></v-row></v-container>
    </div>

  </div>



</template>

<style lang="scss" scoped>
@import "@/assets/common";
#incoming{
  #scrollArea{
    position: absolute;
    width: 100%;
    //height: 80%;
    overflow: auto;
  }
}
</style>


<script>
import { mapActions, mapGetters } from 'vuex'
import FileDropper from '@/components/FileDropper'
import FileIcon from '@/components/FileIcon'
import JobPicker from '@/components/JobPicker'
import Moment from '@/components/Moment'
//import Education from '@/components/Education'
export default {
  name: 'Incoming',

  components: {
    FileDropper, FileIcon, JobPicker, Moment
  },

  data: () => ({
    loading: false,
    types: {
      "EVA": "Machined EVA Prescription",
      "3DP": "3D Printed Prescription",
      "CSV": "Legacy LaserCAM Prescription",
      "EXO_L": "EXO-L Brace Prescription",
    },
    files: [],
  }),

  computed: {
    ...mapGetters('Auth', ['LOGGED_IN', 'TOKEN', 'MODULES', 'ACCOUNT', 'USER']),
    ...mapGetters('API', ['API_BASE']),
  },

  methods:{
    ...mapActions('UI', ['SHOW_NAVBARS', 'NOTIFY', 'SET_PAGE_TITLE', 'PROMPT', 'NAVIGATE', 'CONFIRM']),
    ...mapActions('Auth', ['REQUIRE_LOGIN']),
    ...mapActions('API', ['API']),
    ...mapActions('PRESCRIBE', ['PRESCRIBE_COMMAND']),


    loadInbox(){
      this.loading = true;
      return this.API({ method: 'GET', endpoint: 'files' })
      .then(files => { this.files = files; })
      .finally(() => { this.loading = false; })
    },

    openScansInPrescribe(file){
      let url = `${this.API_BASE}/file/${file.xid}/${file.check_sha256}`;
      url = encodeURIComponent(btoa(url));
      this.PRESCRIBE_COMMAND({ message: "Opening 3D Scans...", command: `viewscans/${url}` });
    },

    openScriptPdf(file){
      console.log(file);
      if(!file.script) return;
      window.open(`${this.API_BASE}/file/${file.xid}/${file.check_sha256}/pdf` ,'_blank');
    },

    downloadFile(file){
      window.open(`${this.API_BASE}/file/${file.xid}/${file.check_sha256}` ,'_blank');
    },

    async deleteFile(file){
      await this.CONFIRM({ title: 'Delete File', message: 'Are you sure you want to delete this file?' });
      this.API({ method: 'DELETE', endpoint: `file/${file.xid}/${file.check_sha256}` })
      .then(this.loadInbox) //are the scripts being deleted after the file is deleted? soft deletes?
      .finally(() => {});
    },

    moveToJob(file){
      let filter = { lasercam_job_number: file.script?.lasercam_job_number };
      this.$refs.JobPicker.pick(filter)
      .then(job => {
        this.API({ method: 'POST', endpoint: `file/${file.xid}/move`, data: { job } })
        .then((job) => { this.NAVIGATE(`job/${job.xid}`); });
      })
    },

    dragEnter(e){ this.$refs['FileDropper'].dragEnter(e); },

    string_bytes(n){
      if(n < 1024) return `${n} B`;
      if(n < 1024e3) return `${(n/1e3).toFixed(1)} KB`;
      if(n < 1024e6) return `${(n/1e6).toFixed(1)} MB`;
      return `${(n/1e9).toFixed(1)} GB`;
    },

    filetype(file){
      return file.filename.split('.').pop().toUpperCase();
    },

    matchingJobs(job){
      this.API({ method: 'GET', endpoint: `script/matching_jobs/${job.script.lasercam_job_number}` })
      .then(console.log);
    }

  },

  mounted() {
    this.REQUIRE_LOGIN(()=>{
      this.SHOW_NAVBARS()
      this.SET_PAGE_TITLE('File Inbox');
      this.loadInbox();
    });
  },

};
</script>
